<template>
  <div>
    <title-bar :title="$t('navigations.orders')"/>
    <div v-if="orders" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('order.create')"
        :addBtnLink="$helper.getAddUrl('order')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="orders"
        :sortable="{}"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:id="data">
          <router-link :to="$helper.getEditUrl('order', data.value.id)">{{ data.value.id }}</router-link>
        </template>
        <template v-slot:vouchersIds="data">
          <div
            v-for="voucher of data.value.vouchers"
            :key="voucher"
          >
            <router-link :to="$helper.getEditUrl('voucher', voucher.id)">
              {{ voucher.code }}
            </router-link>
          </div>
          <div
            v-for="item of data.value.items"
            :key="item"
          >
            <div
                v-if="item.extendedVoucher"
              >
              <router-link :to="$helper.getEditUrl('voucher', item.extendedVoucher.id)">
                {{ item.extendedVoucher.code }}
              </router-link>
            </div>
          </div>
        </template>
        <template v-slot:user="data">
          <span v-if="data.value.client">
            {{
              (data.value.client.givenName && data.value.client.familyName) ? data.value.client.givenName + ' ' + data.value.client.familyName : data.value.client.email
            }}
          </span>
        </template>
        <template v-slot:totalPrice="data">
          {{ $helper.priceFormat(data.value.totalPrice) }}
        </template>
        <template v-slot:status="data">
          <b-badge :variant="orderStatusClass(data.value.status)">{{ data.value.status }}</b-badge>
        </template>
        <template v-slot:financialStatus="data">
          <b-badge :variant="paymentStatusClass(data.value.financialStatus)">{{ data.value.financialStatus }}</b-badge>
        </template>
        <template v-slot:paymentMethod="data">
          <b-badge variant="primary">{{ data.value.paymentMethod.replace("_", " ") }}</b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('order', data.value.id)"/>
            <delete-btn @pressDelete="deleteOrder(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      orders: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      sortable: {
        order: "createdAt",
        sort: "desc",
      },
      columns: [
        {
          label: this.$t('order.orderId'),
          field: "id",
          sortable: true,
        },
        {
          label: this.$t('order.vouchersIds'),
          field: "vouchersIds",
          sortable: false,
        },
        {
          label: this.$t('order.user'),
          field: "user",
          sortable: false,
        },
        {
          label: this.$t('order.total'),
          field: "totalPrice",
          sortable: false,
        },
        {
          label: this.$t('order.status'),
          field: "status",
          sortable: true,
        },
        {
          label: this.$t('order.financialStatus'),
          field: "financialStatus",
          sortable: true,
        },
        {
          label: this.$t('order.paymentMethod'),
          field: "paymentMethod",
          sortable: true,
        },
        {
          label: this.$t('order.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadOrders();
  },
  methods: {
    setSort(offset, limit, order, sort) {
      this.sortable.sort = sort;
      this.sortable.order = order;

      this.loadOrders();
    },
    loadOrders() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        status: ['NEW', 'PROCESSING', 'CLOSED', 'PENDING', 'POSTPONED', 'CANCELLED', 'COMPLETED', 'PICKED_UP', 'CHECKING']
      };
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Orders.getCollection({params}, 'order_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.orders = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadOrders();
    },
    orderStatusClass(status) {
      let className = 'danger'
      if (status === 'PROCESSING') {
        className = 'primary'
      }
      if (status === 'COMPLETED') {
        className = 'success'
      }
      if (status === 'PENDING') {
        className = 'warning'
      }
      return className
    },
    paymentStatusClass(status) {
      let className = 'danger'
      if (status === 'PAID') {
        className = 'success'
      }
      if (status === 'PENDING') {
        className = 'warning'
      }
      return className
    },
    deleteOrder(url) {
      this.deleteByUrl(this.$Orders, url, this.$t('order.deleted'), null, this.loadOrders, this.error);
    },
    error() {
      this.$emit("clearAction");
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadOrders();
    },
    onPageChange() {
      this.loadOrders();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadOrders();
    },
  },
};
</script>
